import { gql } from "@apollo/client";
import MultiValuesField from "controls/MultiValuesField";
import SwitchInput from "inputs/SwitchInput";
import TextInput from "inputs/TextInput";
import React from "react";
import ReadminObjectInput from "readmin_pages/shared/ReadminObjectInput";

export const CourtsFormFragment = gql`
  fragment CourtsFormFragment on Court {
    id
    name
    scorekeeperName
    active
    allowLiveStreams
    advertisingActive
    club {
      id
    }
    venue {
      id
    }
    surface {
      id
    }
  }
`;

export default function CourtsForm({ courts = [{}] }) {
  return (
    <>
      <MultiValuesField
        name="club"
        label="Club"
        disabled={!!courts.find((c) => c.id)}
        defaultValues={courts.map((court) => court.club)}
        input={<ReadminObjectInput optionsTypename="Club" />}
      />
      <MultiValuesField
        name="name"
        label="Name"
        defaultValues={courts.map((court) => court.name)}
        required
        input={<TextInput />}
      />
      <MultiValuesField
        name="scorekeeperName"
        label="Scorekeeper Name"
        defaultValues={courts.map((court) => court.scorekeeperName)}
        input={<TextInput />}
        helperText={
          <>
            If left empty, will use acronym generated from venue and court name, e.g. TDR-C1 (Thinkspace Dominion Road -
            Court 1)
          </>
        }
      />
      <MultiValuesField
        name="active"
        label="Active"
        defaultValues={courts.map((court) => court.active)}
        input={<SwitchInput />}
      />
      <MultiValuesField
        name="allowLiveStreams"
        label="Allow Live Streams"
        defaultValues={courts.map((court) => court.allowLiveStreams)}
        input={<SwitchInput />}
      />
      <MultiValuesField
        name="advertisingActive"
        label="Advertising Active"
        defaultValues={courts.map((court) => court.advertisingActive)}
        input={<SwitchInput />}
      />
      <MultiValuesField
        name="venue"
        label="Venue"
        defaultValues={courts.map((court) => court.venue)}
        input={<ReadminObjectInput optionsTypename="Venue" />}
      />
      <MultiValuesField
        name="surface"
        label="Surface"
        defaultValues={courts.map((court) => court.surface)}
        input={<ReadminObjectInput optionsTypename="Surface" />}
      />
    </>
  );
}
