import { gql } from "@apollo/client";
import Field from "controls/Field";
import imageFakeVideoFrame from "images/fake_video_frame.jpg";
import RectInput from "inputs/RectInput";
import SelectInput from "inputs/SelectInput";
import TextInput from "inputs/TextInput";
import UploaderInput from "inputs/UploaderInput";
import React, { useEffect, useState } from "react";
import ReadminObjectInput from "readmin_pages/shared/ReadminObjectInput";

export const AdAssetFormFragment = gql`
  fragment AdAssetFormFragment on AdAsset {
    id
    club {
      id
    }
    name
    assetType
    assetUploader
    videoOverlayPosition
    gameEmailBannerLink
  }
`;

export default function AdAssetForm({ adAsset }) {
  adAsset = {
    assetType: null,
    assetUploader: null,
    name: null,
    ...adAsset,
  };
  const [name, nameSet] = useState(adAsset.name);
  const [assetType, assetTypeSet] = useState(adAsset.assetType);
  const [assetUploader, assetUploaderSet] = useState(adAsset.assetUploader);

  // if assetType changed, clear uploaded file
  useEffect(() => {
    return () => {
      if (!assetType) assetUploaderSet(null);
    };
  }, [assetType]);

  // auto-fill name from uploaded file basename
  useEffect(() => {
    if (!name && assetUploader) nameSet(assetUploader.name.replace(/\.[^.]+$/, ""));
  }, [assetUploader]);

  return (
    <>
      <Field
        name="club"
        label="Club"
        defaultValue={adAsset.club}
        disabled={!!adAsset.id}
        required
        input={<ReadminObjectInput optionsTypename="Club" />}
      />
      <Field
        name="assetType"
        label="Asset Type"
        value={assetType}
        onChange={assetTypeSet}
        disabled={!!adAsset.id}
        required
        input={
          <SelectInput
            options={[
              ["Billboard", "billboard"],
              ["Video Overlay", "video_overlay"],
              ["Centre Court Underlay", "centre_court_underlay"],
              ["Game Email Banner", "game_email_banner"],
            ]}
          />
        }
      />
      <Field
        //
        name="assetUploader"
        label="Asset"
        value={assetUploader}
        onChange={assetUploaderSet}
        disabled={!assetType}
        required
        input={
          <UploaderInput
            accept={
              {
                video_overlay: "image/*,video/*",
              }[assetType] || "image/*"
            }
          />
        }
      />
      <Field
        //
        name="name"
        label="Name"
        value={name}
        onChange={nameSet}
        required
        input={<TextInput />}
      />
      {assetType === "video_overlay" && (
        <Field
          name="videoOverlayPosition"
          label="Video Overlay Position"
          defaultValue={adAsset.videoOverlayPosition}
          required
          input={
            <RectInput
              width={1280}
              height={720}
              round
              backgroundImageUrl={imageFakeVideoFrame}
              fillImageUrl={assetUploader?.url || assetUploader?.blobUrl}
            />
          }
          helperText="Select a rectangle area where the overlay will be put on the output video."
        />
      )}
      {assetType === "game_email_banner" && (
        <Field
          name="gameEmailBannerLink"
          label="Game Email Banner Link"
          defaultValue={adAsset.gameEmailBannerLink}
          input={<TextInput type="url" />}
          helperText="The link to be opened when the game email banner is clicked."
        />
      )}
    </>
  );
}
