import { gql } from "@apollo/client";
import MultiValuesField from "controls/MultiValuesField";
import sameOrNull from "helpers/sameOrNull";
import useData from "hooks/useData";
import PasswordInput from "inputs/PasswordInput";
import SwitchInput from "inputs/SwitchInput";
import TextInput, { IpAdddressInput, MacAddressInput } from "inputs/TextInput";
import React, { useState } from "react";
import ReadminObjectInput from "readmin_pages/shared/ReadminObjectInput";

export const CamerasFormFragment = gql`
  fragment CamerasFormFragment on Camera {
    id
    cameraId
    enabled
    cameraModel {
      id
    }
    venueServer {
      id
    }
    ipAddress
    macAddress
    details
    allowLiveStreams
    deviceUsername
    devicePassword
    captureH265
    captureFfmpegArgs
  }
`;

export default function CamerasForm({ cameras }) {
  cameras = cameras.map((camera) => ({
    enabled: true,
    ...camera,
  }));

  const [cameraModel, cameraModelSet] = useState(() => sameOrNull(cameras.map((camera) => camera.cameraModel)));
  const [data] = useData(
    gql`
      query CamerasForm($cameraModelId: ID) {
        cameraModel(id: $cameraModelId) {
          id
          supportsH265
        }
      }
    `,
    { cameraModelId: cameraModel?.id },
  );

  return (
    <>
      <MultiValuesField
        label="Camera ID"
        name="cameraId"
        required
        defaultValues={cameras?.map((camera) => camera.cameraId)}
        input={<TextInput inputProps={{ pattern: "cam-.+" }} />}
        helperText={<>cam-venue-identifier e.g. cam-ags-a</>}
      />
      <MultiValuesField
        name="venueServer"
        label="Venue Server"
        defaultValues={cameras?.map((camera) => camera.venueServer)}
        input={<ReadminObjectInput optionsTypename="VenueServer" />}
      />
      <MultiValuesField
        name="ipAddress"
        label="IP Address"
        required
        defaultValues={cameras?.map((camera) => camera.ipAddress)}
        input={<IpAdddressInput />}
      />
      <MultiValuesField
        name="macAddress"
        label="MAC Address"
        defaultValues={cameras?.map((camera) => camera.macAddress)}
        input={<MacAddressInput />}
      />
      <MultiValuesField
        name="deviceUsername"
        label="Device Username"
        defaultValues={cameras?.map((camera) => camera.deviceUsername)}
        input={<TextInput />}
      />
      <MultiValuesField
        name="devicePassword"
        label="Device Password"
        defaultValues={cameras?.map((camera) => camera.devicePassword)}
        input={<PasswordInput />}
      />
      <MultiValuesField
        name="cameraModel"
        label="Model"
        required
        defaultValues={cameras?.map((camera) => camera.cameraModel)}
        onChange={cameraModelSet}
        input={<ReadminObjectInput optionsTypename="CameraModel" />}
      />
      <MultiValuesField
        name="details"
        label="Details"
        defaultValues={cameras?.map((camera) => camera.details)}
        input={<TextInput multiline minRows={5} />}
      />
      <MultiValuesField
        label="Enabled"
        name="enabled"
        defaultValues={cameras?.map((camera) => camera.enabled)}
        input={<SwitchInput />}
        helperText={<>Enables video processing</>}
      />
      <MultiValuesField
        label="Allow live streams"
        name="allowLiveStreams"
        defaultValues={cameras?.map((camera) => camera.allowLiveStreams)}
        input={<SwitchInput />}
      />
      {data?.cameraModel && (
        <MultiValuesField
          label="Capture H265"
          name="captureH265"
          disabled={!data.cameraModel.supportsH265}
          defaultValues={cameras?.map((camera) => camera.captureH265)}
          input={<SwitchInput />}
          helperText="Capture H265 from the camera, reduce bandwidth and storage."
        />
      )}
      <MultiValuesField
        label="Capture FFmpeg args"
        name="captureFfmpegArgs"
        defaultValues={cameras?.map((camera) => camera.captureFfmpegArgs)}
        input={<TextInput multiline />}
        helperText="Custom FFmpeg args to use when capturing video from the camera. One arg per line. Should usually be left blank."
      />
    </>
  );
}
