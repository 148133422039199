import { TextField } from "@mui/material";
import { isValid, parseISO } from "date-fns";
import React, { useId } from "react";

function formatDate(dateStr) {
  if (!dateStr) return "";
  return dateStr;
}

function parseDate(str) {
  if (!isValid(parseISO(str))) return null;
  return str;
}

DateInput.defaultValue = null;

export default function DateInput({ value, readOnly = false, onChange, ...others }) {
  const id = useId();

  return (
    <TextField
      fullWidth
      id={id}
      value={formatDate(value)}
      inputProps={{
        readOnly,
      }}
      InputLabelProps={{ shrink: true }}
      onChange={(event) => onChange(parseDate(event.target.value))}
      type="date"
      {...others}
    />
  );
}
